import Finance from "./model/Finance";
import { ryouToDiffTag, ryouToTag, yenToDiffTag, yenToTag } from "./Util";
import "./DiffFinanceView.css";


export default function DiffFinanceView({ befFin, aftFin }: { befFin: Finance, aftFin: Finance }) {
    return <div className="diffFinance">

        <div className="title">売却係</div>
        <div className="list">
            <div>
                <div className="label">損益　　</div>
                <div className="value">{yenToTag(aftFin.mouke)}<span className="unit">円</span></div>
                <div className="diff">{yenToDiffTag(aftFin.mouke.minus(befFin.mouke))}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">売却金額</div>
                <div className="value">{yenToTag(aftFin.uriYen, false)}<span className="unit">円</span></div>
                <div className="diff">{yenToDiffTag(aftFin.uriYen.minus(befFin.uriYen))}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">売却原価</div>
                <div className="value">{yenToTag(aftFin.jyoutoCost, false)}<span className="unit">円</span></div>
                <div className="diff">{yenToDiffTag(aftFin.jyoutoCost.minus(befFin.jyoutoCost))}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">取得単価</div>
                <div className="value">{yenToTag(aftFin.kaiTanka, false)}<span className="unit">円</span></div>
                <div className="diff">{yenToDiffTag(aftFin.kaiTanka.minus(befFin.kaiTanka))}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">売却単価</div>
                <div className="value">{yenToTag(aftFin.uriTanka, false)}<span className="unit">円</span></div>
                <div className="diff">{yenToDiffTag(aftFin.uriTanka.minus(befFin.uriTanka))}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">売却数量</div>
                <div className="value">{ryouToTag(aftFin.uriRyou, false)}</div>
                <div className="diff">{ryouToDiffTag(aftFin.uriRyou.minus(befFin.uriRyou))}</div>
            </div>
        </div>

        <div className="title">保有係</div>
        <div className="list">
            <div>
                <div className="label">含み損益　</div>
                <div className="value">{yenToTag(aftFin.zanFukumiMouke)}<span className="unit">円</span></div>
                <div className="diff">{yenToDiffTag(aftFin.zanFukumiMouke.minus(befFin.zanFukumiMouke))}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">保有評価額</div>
                <div className="value">{yenToTag(aftFin.zanValueYen, false)}<span className="unit">円</span></div>
                <div className="diff">{yenToDiffTag(aftFin.zanValueYen.minus(befFin.zanValueYen))}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">取得原価　</div>
                <div className="value">{yenToTag(aftFin.zanCost, false)}<span className="unit">円</span></div>
                <div className="diff">{yenToDiffTag(aftFin.zanCost.minus(befFin.zanCost))}<span className="unit">円</span></div>
            </div>
            <div>
                <div className="label">保有数量　</div>
                <div className="value">{ryouToTag(aftFin.zanRyou, false)}</div>
                <div className="diff">{ryouToDiffTag(aftFin.zanRyou.minus(befFin.zanRyou))}</div>
            </div>
        </div>
    </div>;
}
